<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ scData.StockPropertyNo }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >STOCK CARD</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(scData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Stock No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <span class="d-flex">
              <v-subheader>{{ scData.StockPropertyNo }}</v-subheader>
              <v-btn icon class="mt-1" @click="changeStockNo(scData.StockPropertyNo)"><v-icon>mdi-pencil-box</v-icon></v-btn>
              </span>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Item Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ scData.ItemName }}</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-subheader class="font-weight-bold">Item Description:</v-subheader>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-4">
              {{ scData.ItemDescription }}
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                :headers="headers"
                :items="itemList"
                class="elevation-3 mt-4 DarkWhite"
                :expanded.sync="itemList"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >Acceptance and Issuance</span
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(scData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.Qty)}}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.Balance)}}</span>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">{{ formatPrice(item.UnitCost)}}</span>
                </template>
                <!-- <template v-slot:[`item.Action`]="{ item }">
                  <v-btn color="header" x-small outlined @click="viewRIS(item)">View</v-btn>
                </template> -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="my-5">
                      <v-data-table
                        :headers="headers2"
                        :items="item.rislist"
                        class="elevation-0 mt-4"
                      >
                        <template v-slot:[`item.Qty`]="{ item }">
                          <span class="text-no-wrap">{{ formatQty(item.Qty)}}</span>
                        </template>
                        <template v-slot:[`item.receiver`]="{ item }">
                          {{ item.office}} - {{item.receiver}}
                        </template>
                        <template v-slot:[`item.Balance`]="{ item }">
                          <span class="text-no-wrap">{{ formatQty(item.Balance) || 0}}</span>
                        </template>
                        <template v-slot:[`item.RSMIDate`]="{ item }">
                          <span class="text-no-wrap">{{ formatDate(item.RSMIDate)}}</span>
                        </template>
                      </v-data-table>


                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogChangeSPN" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Change Stock Number</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogChangeSPN = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="StockNo"
              label="Stock Number"
              :rules="[formRules.required, spn_result.length == 0]"
              @keyup="checkStockPropertyNo()"
              outlined
              dense
              color="header"
            ></v-text-field>
            <v-alert
            type="success"
            color="header"
            v-if="spn_result.length == 0"
            dense
            >
            Unique!
            </v-alert>
            <v-alert
            type="warning"
            v-else
            dense
            >
            Already Exist!
            </v-alert>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="dialogChangeSPN = false">
          Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmChangeSPN()"
          >
          <v-icon>delete</v-icon>
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    scData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    link: "",
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "DateCreated",
      },
      {
        text: "IAR Code",
        align: "start",
        sortable: false,
        value: "IARCODE",
      },
      {
        text: "Receipt(Qty)",
        align: "center",
        sortable: false,
        value: "Qty",
      },
      {
        text: "Balance",
        value: "Balance",
        align: "center",
        sortable: false,
      },
      {
        text: "Unit Price",
        value: "UnitCost",
        align: "end",
        sortable: false,
      },
      // { text: "Action", value: "Action", align: "center", sortable: false },
    ],
    headers2: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "DateCreated",
      },
      {
        text: "RIS Code",
        align: "start",
        sortable: false,
        value: "RISCODE",
      },
      {
        text: "Issuance(Qty)",
        align: "center",
        sortable: false,
        value: "Qty",
      },
      {
        text: "Office/User",
        value: "receiver",
        align: "center",
        sortable: false,
      },
      {
        text: "Balance",
        value: "Balance",
        align: "center",
        sortable: false,
      },
      {
        text: "RSMI",
        value: "RSMICODE",
        align: "center",
        sortable: false,
      },
      {
        text: "Date(RSMI)",
        value: "RSMIDate",
        align: "center",
        sortable: false,
      },
      // { text: "Action", value: "Action", align: "center", sortable: false },
    ],
    itemList: [],
    submitbtn: false,
    dialogConfirmSubmit: false,
    dialogUnpublish: false,
    isLoading: false,
    risData: [],
    editData: [],
    expandedItemList: [],
    showRIS: false,
    dialogChangeSPN: false,
    StockNo: null,
    spn_result: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
  }),
  watch: {
    scData: {
      handler(data) {
        if (data.ItemID) {
          this.getSCList();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    print() {
      let link = this.apiUrl + "/sc/report/0/0/" + this.scData.ItemID + "/" + this.scData.StockPropertyNo;
      window.open(link);
    },
    closeDialog() {
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewSC", false);
    },
    getSCList() {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("ItemID", this.scData.ItemID);
      data.append("StockPropertyNo", this.scData.StockPropertyNo);
      data.append("ItemName", this.scData.ItemName);
      data.append("ItemDescription", this.scData.ItemDescription);
      this.axiosCall("/sc/getAcceptanceAndIssuance", "POST", data).then((res) => {
        this.itemList = res.data.data;
        this.itemList.forEach(item => {
          let balance = parseFloat(item.Qty)
          item.Balance = balance
          item.rislist.forEach(item2 => {
            item2.Balance = balance - parseFloat(item2.Qty)
            balance = item2.Balance
          });
        });
        this.loading = false;
      });
    },
    changeStockNo(data){
      this.StockNo = data;
      this.dialogChangeSPN = true;
    },
    confirmChangeSPN(){
      let data = new FormData();
      data.append("ItemID", this.scData.ItemID);
      data.append("StockPropertyNo", this.scData.StockPropertyNo);
      data.append("ItemName", this.scData.ItemName);
      data.append("ItemDescription", this.scData.ItemDescription || '');
      data.append("StockPropertyNo_new", this.StockNo);
      this.axiosCall("/changeStockPropertyNo", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Stock Number was successfully changed.";
            this.dialogProcess = false;
            this.scData.StockPropertyNo = this.StockNo;
            this.dialogChangeSPN = false;
            this.getSCList();
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
        this.loading = false;
      });
    },
    checkStockPropertyNo(){
      let data = new FormData();
      data.append("ItemID", this.scData.ItemID);
      data.append("StockPropertyNo", this.scData.StockPropertyNo);
      data.append("ItemName", this.scData.ItemName);
      data.append("ItemDescription", this.scData.ItemDescription || '');
      data.append("StockPropertyNo_new", this.StockNo);
      this.axiosCall("/checkStockPropertyNo", "POST", data).then((res) => {
        if(res.data.status){
            this.spn_result = res.data.data;
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.header = "System Message";
            this.fadeAwayMessage.message = "Something went wrong!";
          }
        this.loading = false;
      });
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>